
// import { useEffect, useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { CircularProgress, Box, Typography, Button } from "@mui/material";
// import { APP_NAME } from "../../config";
// import { RHFTextField } from "../../components/hook-form";

// function VerifyG() {

//   useEffect(() => {
//     let timer;
//     if (timeLeft > 0) {
//       timer = setInterval(() => {
//         setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
//       }, 1000);
//     } else {
//       clearInterval(timer);
//     }
//     return () => clearInterval(timer);
//   }, [timeLeft]);

//   useEffect(() => {
//     localStorage.setItem("timeLeft", timeLeft.toString());
//   }, [timeLeft]);

//   const formatTime = (seconds) => {
//     const minutes = Math.floor(seconds / 60);
//     const remainingSeconds = seconds % 60;
//     return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
//   };

//   const [timeLeft, setTimeLeft] = useState(() => {
//     const storedTimeLeft = localStorage.getItem("timeLeft");
//     return storedTimeLeft ? parseInt(storedTimeLeft, 10) : 300; // 5 minutes in seconds
//   });

//   const [loading, setLoading] = useState(false);
//   const fineemail = localStorage.getItem("user_g");

//   return (
//     <Formik
//       initialValues={{
//         otp: "",
      
//       }}
//       validationSchema={Yup.object().shape({
//         otp: Yup.string().max(6).required("Otp is required"),
     
//       })}
//       onSubmit={async (values, { setErrors, setSubmitting }) => {
//         try {
//           setLoading(true);
//           const data = {
//             otp: values.otp,
           
//           };
//           const response = await axios.post(
//             "/api/forgot/password/reset/g/make/long/so/not/see/ehhn/confirm/password",
//             data
//           );
//           if (response.data.status === 200) {
//             Swal.fire({
//               icon: "success",
//               text: `${response?.data?.message}`
//             });
//             setTimeout(() => {
//               window.location.pathname = "/dashboard/app";
//             }, 4000);
//           }
//         } catch (error) {
//           setErrors({ submit: error?.response?.data?.message ?? "No Internet." });
//         } finally {
//           setLoading(false);
//         }
//       }}
//     >
//       {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
//         <Form noValidate onSubmit={handleSubmit}>
//           {errors.submit && (
//             <Box sx={{ mt: 5 }}>
//               <h5 style={{ color: "red" }} className="mb-0">
//                 {errors.submit}.
//               </h5>
//             </Box>
//           )}
//           <Typography component="label" variant="button" color="white" fontWeight="medium">
//             The Otp will take {formatTime(timeLeft)} To Arrive. Check Your Email.
//           </Typography>
//           <Box mb={2}>
//             <Box mb={1} ml={0.5}>
//             <RHFTextField name="otp" label="Input Otp" />
//             </Box>
             
//           </Box>
         
        
//           <Box mt={4} mb={1}>
//             <Button color="info" fullWidth type="submit">
//               {loading ? <CircularProgress color="secondary" size={24} /> : "Proceed"}
//             </Button>
//           </Box>
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default VerifyG;


import React, { useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from '../../utils/axios';



function VerifyG() {
  
  const headingStyle = {
    textAlign: "center",
    color: "green",
    fontFamily: "Arial, sans-serif",
    fontSize: "2rem",
    fontWeight: "bold",
    padding: "20px",
    background: "#f8f8f8",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  };
  const fineemail = localStorage.getItem("user_g");
  const [loading, setLoading] = useState(false);
  const [anoloading, setanoLoading] = useState(false);
  const handleResendCode = async({setErrors}) => {
    try {
        setanoLoading(true)
         const data = {
            email : localStorage.getItem('user_g')
         }
        const response = await axios.post(
          "/api/forgot/password/reset/resend/code/8weuweusdhjh",
          data
        );
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            text: `${response?.data?.message}`
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setanoLoading(false)
      }
}

  return (
    <div>
      <h1 style={headingStyle}>Login Verification</h1>
      <br /> <br />
      <Typography style={{ textAlign: "center" }} color="black" fontWeight="medium">
        Input The Otp  Sent To Your Email, {fineemail.slice(0, 3)}****{fineemail.slice(-13)},
          The Otp might take up to  Five (5) Minutes To Arrive. Check Your Email For The Otp.
        </Typography>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={Yup.object().shape({
          otp: Yup.string()
            .matches(/^[0-9]+$/, 'OTP must contain only numbers')
            .max(6, 'OTP must be at most 6 characters')
            .required('OTP is required'),
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            setLoading(true);
            const data = {
              otp: values.otp,
            };
            const response = await axios.post(
              "/api/forgot/password/reset/g/make/long/see/hsjeu9sh",
              data
            );
            console.log(response);
            if(response.data.status ===  200) {
                const godly = response.data.token
                localStorage.setItem('accessToken', godly)
                window.location.pathname = "/dashboard/app"
             
            }
          } catch (error) {
            setErrors({ submit: error?.message ?? "No Internet." });
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Box sx={{ mt: 5 }}>
    {errors.submit && (
      <Box>
        <h5 style={{ color: "red" }} className="mb-0">
          {errors.submit}.
        </h5>
      </Box>
    )}
  </Box>
</div>

            <Box display="flex" justifyContent="center">
              <Box>
                <TextField 
                  name="otp"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{}}
                  label="OTP"
                />
                {touched.otp && errors.otp && (
                  <Box mt={1}>
                    <h7 style={{ color: 'red' }}>{errors.otp}</h7>
                  </Box>
                )}
              </Box>
            </Box>
            <Box mt={4} mb={1} style={{ textAlign: 'center' }}>
              <Button variant="contained" style={{ background: 'green' }} type="submit">
                {loading ? <CircularProgress color="secondary" size={24} /> : "Proceed"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      {/* Button for Resending Code */}
      <Box mt={2} mb={4} style={{ textAlign: 'center' }}>
        <Button variant="outlined" onClick={handleResendCode}>
        {anoloading ? <CircularProgress color="secondary" size={24} /> : "Resend Otp"}
        </Button>
      </Box>
    </div>
  );
}

export default VerifyG;
